/*!
 * GRIDDER (http://www.oriongunning.com/)
 * Version 1.1 - 20141003 (Modified by 052b Creative Agency - http://www.052b.com)
 * This work is licensed under a Creative Commons Attribution 3.0 Unported License.
 */
;(function($) {

    $.fn.Gridder = function(options) {

        var mybloc;
        var gridder = $('<div class="gridder-show"></div>');
        var animationSpeed = 600;
        var animationEasing = "linear";
        var visible = false;
        
        return this.each(function() {

            $('.gridder-list').click(function(e) {
                e.preventDefault();
                
                /* REMOVES PREVIOUS BLOC */
                $('.gridder-show').remove();

                /* ENSURES THE CORRECT BLOC IS ACTIVE */
                if (!$(this).hasClass('imactive')) {
                    $('.imactive').removeClass('imactive');
                    $(this).addClass("imactive");
                }
                
                /* ADD LOADING BLOC */
                mybloc = gridder.insertAfter(this);
 
                /* EXPANDED OUTPUT */
                var currentcontent = $(this).find('.gridder-content').html();
                //var currentimage = $(this).find('.gridder-thumb').html();
                var bigimage = $(this).find('img').data('bigimage');


                htmlcontent = "<div class=\"padding\">";
                    htmlcontent += "<a class=gridder-close><i class=\"fa fa-times\" aria-hidden=\"true\"></i></a>";
                    htmlcontent += "<a class=\"gridder-nav prev\"><i class=\"fa fa-arrow-left\"></i></a>";
                    htmlcontent += "<a class=\"gridder-nav next\"><i class=\"fa fa-arrow-right\"></i></a>";
                    htmlcontent += '<div class="image"><img src="'+ bigimage+'"></div>';
//                     htmlcontent += "<div class=content>"+ currentcontent+"</div>";
                htmlcontent += "</div>";
                
                
                
                mybloc.html(htmlcontent);


               
                if (!visible) {
                    mybloc.find('.padding').slideDown(animationSpeed, animationEasing, function() {
                        visible = true;
                    });
                } else {
                    mybloc.find('.padding').fadeIn(animationSpeed, animationEasing, function() {
                        visible = true;
                    });
                }
                

                
                /* SCROLLS TO CORRECT BLOC */
                $('html, body').animate({
                    scrollTop: $('.gridder-show').offset().top - $('.navbar').outerHeight( true )
                }, animationSpeed );
                
                
                
                
                
            });
            
             /* NEXT BUTTON */
            $('.gridder').on('click', '.gridder-nav.next', function() {
                $(this).parents('.gridder-show').next().trigger('click');
                return false;
            });

            /* PREVIOUS BUTTON */
            $('.gridder').on('click', '.gridder-nav.prev', function() {
                $(this).parents('.gridder-show').prev().prev().trigger('click');
                return false;
            });
            
            /* CLOSE BUTTON */
            $('.gridder').on('click', '.gridder-close', function()
            {
                $('.imactive').removeClass('imactive');
                $('.gridder-show').slideUp( animationSpeed, animationEasing, function() {
                    $( this ).remove().removeAttr('style');
                });
                
                
                
                visible = !visible;
            });

        });
    };
})(jQuery);